import React, { useMemo } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend, ChartDataset,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { CloseIcon, legendIcon } from 'src/assets/icons';
import classes from './styles.module.scss';

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend);

type Props = {
  datasets: ChartDataset[];
  measurementUnit?: string;
  chartName?: string;
  xLabels: string[];
  length?: number;
  chartHeight?: number;
};

const BarChart: React.FC<Props> = ({
  datasets,
  measurementUnit,
  chartName,
  xLabels,
  length,
  chartHeight,
}) => {
  const [showLegend, setShowLegend] = React.useState(false);
  const handleShowLegend = () => {
    setShowLegend((prev) => !prev);
  };

  const options = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: !chartHeight,
      plugins: {
        legend: {
          display: false,
        },
        decimation: {
          enabled: true,
        },
        datalabels: {
          display: false,
        },
      },
      scales: {
        y: {
          ticks: {
            color: '#383874',
            padding: 14,
            font: {
              size: 16,
            },
          },
        },
        x: {
          ticks: {
            color: '#383874',
            font: {
              size: 12,
            },
          },
        },
      },
    };
  }, []);

  const data = {
    labels: length ? xLabels.slice(0, 3) : xLabels,
    datasets,
  };

  return (
    <div className={classes['wrapper']}>
      {chartName && <h1>{chartName}</h1>}
      {measurementUnit && <p>{measurementUnit}</p>}
      {datasets.length > 0 && datasets[0].backgroundColor !== '' && (
        <div className={classes['custom-legend']}>
          {!showLegend ? (
            <img
              style={{
                cursor: 'pointer',
              }}
              onClick={handleShowLegend}
              src={legendIcon}
              alt="Посмотреть"
            />
          ) : (
            <>
              <img
                onClick={handleShowLegend}
                className={classes['closeIcon']}
                src={CloseIcon}
                alt="X"
              />
              {datasets.map(
                (dataset, index) =>
                  dataset.label && (
                    <div className={classes['legend-wrapper']} key={index}>
                      <div
                        className={` ${classes['dashed-line']}`}
                        style={{
                          borderBottom: `${`5px ${
                            dataset.label?.includes('Норма') ? 'dashed' : 'solid'
                          } ${dataset.borderColor}`}`,
                        }}
                      ></div>
                      <p>
                        {dataset.label?.includes('Норма')
                          ? dataset.label
                          : `Факт, ${dataset.label}`}
                      </p>
                    </div>
                  ),
              )}
            </>
          )}
        </div>
      )}
      <div
        style={{
          height: chartHeight ? `${chartHeight}px` : 'auto',
          width: '100%',
        }}
      >
        <Bar options={options} data={data as any}></Bar>
      </div>
    </div>
  );
};

export default BarChart;
