import { FC } from 'react';
import styles from './styles.module.scss';

type TDataSourceItem = {
  key: string | number;
  [key: string]: string | number | JSX.Element;
};

type TTableProps = {
  dataSource: TDataSourceItem[];
  loading?: boolean;
  //   pagination?: {
  //     page: number
  //     setPage: React.Dispatch<React.SetStateAction<number>>
  //     totalSize: number
  //     pageSize: number
  //   }
};

const CustomTable: FC<TTableProps> = ({ dataSource, loading }) => {
  return (
    <div className={styles['table-wrapper']}>
      <div className={styles['table-container']}>
        {loading ? (
          <div className={styles['skeleton-loader-row']}>
            {[...Array(5)].map((_, index) => (
              <div key={index} className={styles['skeleton-loader']}></div>
            ))}
          </div>
        ) : (
          <table>
            <thead>
              <tr>
                {dataSource.length > 0 ? (
                  Object.keys(dataSource[0]).map(
                    (key) =>
                      key !== 'key' && (
                        <th key={key}>
                          <div className={styles['flex-col']}>
                            <span>
                              {key.split(',')[0] + (key.split(',').length > 1 ? ',' : '')}
                            </span>
                            <span>{key.split(',')[1]}</span>
                          </div>
                        </th>
                      ),
                  )
                ) : (
                  <th className={styles['no-data']}>Нет данных</th>
                )}
              </tr>
            </thead>
            <tbody>
              {dataSource.length > 0 &&
                dataSource.map((item) => (
                  <tr key={item.key}>
                    {Object.keys(item).map(
                      (key) => key !== 'key' && <td key={key}>{item[key]}</td>,
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default CustomTable;
