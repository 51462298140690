import React, { useEffect, useState } from 'react';
import classes from './styles.module.scss';
import Card from 'src/components/card';
import { column3ZV, columns3 } from '../consts';
import { getTable3Data } from 'src/requests/source';
import { format } from 'date-fns';
import { IExceedanceTableData, ISource } from 'src/consts/types';

type PropsType = {
  selectedSources: ISource[];
};

const Table3: React.FC<PropsType> = ({ selectedSources }) => {
  const [tableData, setTableData] = useState<IExceedanceTableData[]>([]);

  useEffect(() => {
    if (selectedSources.length > 0) {
      getTable3Data(selectedSources.map((item) => item.id)).then((res) => {
        setTableData(res.list);
      });
    } else {
      setTableData([]);
    }
  }, [selectedSources]);

  return (
    <Card
      styles={{
        maxWidth: '100%',
        display: 'block',
        justifyContent: 'center',
        maxHeight: '500px',
        overflow: 'auto',
      }}
    >
      <div className={classes['wrapper']}>
        <div
          className={classes['table__name']}
          style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}
        >
          Превышения
        </div>
        <div style={{ width: '100%', overflowX: 'auto' }}>
          <table className={classes['table']} style={{ minWidth: '100%' }}>
            <thead>
              <tr>
                {columns3.map((column) => (
                  <th key={`header_${column.title}`} rowSpan={2}>
                    <div className={classes['headers']}>
                      <p>{column.title}</p>
                    </div>
                  </th>
                ))}
                <th colSpan={3}>Превышения выбросов ЗВ</th>
                <th colSpan={2}>Превышения концентрации ЗВ</th>
              </tr>
              <tr>
                {column3ZV.map((column) => (
                  <th key={`header_${column.title}`}>
                    <div className={classes['headers']}>
                      <p>{column.title}</p>
                    </div>
                  </th>
                ))}
                <th>мг/нм3</th>
                <th>
                  Длительность,
                  <br />
                  мин
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData
                  .sort((a, b) => a.sourceName.localeCompare(b.sourceName))
                  .map((item, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td
                          rowSpan={
                            Object.keys(item.params).length + Object.keys(item.params).length * 2
                          }
                          style={{
                            fontSize: '32px',
                            fontWeight: 700,
                          }}
                        >
                          {item.sourceName}
                        </td>
                      </tr>

                      {Object.entries(item.params)?.map(([key, value], subIndex) => (
                        <React.Fragment key={subIndex}>
                          <tr>
                            <td
                              style={{
                                fontSize: '32px',
                                fontWeight: 700,
                              }}
                            >
                              {key}
                            </td>
                            <td>{value.upValue}</td>
                            <td>{value.downValue}</td>
                            <td>{value.duration}</td>
                            <td>{value.cupValue}</td>
                            <td>{value.cduration}</td>
                          </tr>
                          <tr style={{ backgroundColor: '#CDCDE133', height: 'fit-content' }}>
                            <td colSpan={1}>
                              <table
                                style={{
                                  width: '100%',
                                }}
                              >
                                <tbody>
                                  <tr style={{ borderBottom: 'none' }}>
                                    <td
                                      style={{
                                        color: '#38387480',
                                        whiteSpace: 'nowrap',
                                      }}
                                    >
                                      Дата и время начала
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        color: '#38387480',
                                        whiteSpace: 'nowrap',
                                      }}
                                    >
                                      Дата и время окончания
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td colSpan={3}>
                              <table
                                style={{
                                  width: '100%',
                                }}
                              >
                                <tbody>
                                  <tr style={{ borderBottom: 'none' }}>
                                    <td>
                                      {value?.minDate &&
                                        format(new Date(value?.minDate), 'dd.MM.yyyy HH:mm')}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {value?.maxDate &&
                                        format(new Date(value?.maxDate), 'dd.MM.yyyy HH:mm')}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td colSpan={2}>
                              <table
                                style={{
                                  width: '100%',
                                }}
                              >
                                <tbody>
                                  <tr style={{ borderBottom: 'none' }}>
                                    <td>
                                      {value?.cminDate &&
                                        format(
                                          new Date(value?.cminDate as number),
                                          'dd.MM.yyyy HH:mm',
                                        )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {value?.cmaxDate &&
                                        format(
                                          new Date(value?.cmaxDate as number),
                                          'dd.MM.yyyy HH:mm',
                                        )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    </Card>
  );
};

export default Table3;
