import { FC, SetStateAction, useEffect, useState } from 'react';
import CustomTable from 'src/components/custom-table';
import {
  getChartData,
  getCompanyInfo,
  getCompanyList,
  getCompanySourceList,
  getDischarges,
  getTableLastTwenty,
} from 'src/requests/source';
import {
  calculateAverages,
  findColorById,
  getColorLinearGraph,
  getShortName,
} from 'src/utils/helpers';
import classes from './styles.module.scss';
import Select from 'src/components/select';
import { ICompany, IDateRange, ISource, SourceItem } from 'src/consts/types';
import { format, set } from 'date-fns';
import SelectDates from '../main/SelectDates';
import LineChart, { IDataset } from 'src/components/charts/line-chart';
import Card from 'src/components/card';

interface Param {
  value: number;
  limit: number | null;
  dist: number | null;
}

interface ListItem {
  sourceName: string;
  type: string;
  companyId: number;
  params: {
    WasteWaterTemperature: Param;
    ElectricalConductivity: Param;
    HydrogenIndex: Param;
    Turbidity: Param;
    WasteWaterFlowmeter: Param;
  };
}

export interface ResponseData {
  success: boolean;
  msg: string | null;
  list: ListItem[];
  totalSize: number;
}

export type IDischargeType =
  | 'Turbidity'
  | 'Totalizer'
  | 'WasteWaterTemperature'
  | 'ElectricalConductivity';

const getDischargeNameByKey = (key: IDischargeType) => {
  switch (key) {
    case 'Turbidity':
      return {
        title: 'Мутность, ЕМФ',
        unit: 'Усреднение, 20 мин',
      };
    case 'Totalizer':
      return {
        title: 'Дополнительная информация',
        unit: 'Объем (счетчик), м3',
      };
    case 'WasteWaterTemperature':
      return {
        title: 'Температура сточных вод, 0С',
        unit: 'Усреднение, 20 мин',
      };
    case 'ElectricalConductivity':
      return {
        title: 'Электропроводность, мкС-микросименс',
        unit: 'Усреднение, 20 мин',
      };
    default:
      return null;
  }
};

const Discharges: FC = () => {
  const [tableState, setTableState] = useState<ListItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCompanies, setSelectedCompanies] = useState<ICompany[]>([]);
  const [companyListWithLevel, setCompanyListWithLevel] = useState<ICompany[]>([]);
  const [selectedRangeType, setSelectedRangeType] = useState<string>('TWENTY_MINUTE');
  const [selectedRange, setSelectedRange] = useState<IDateRange>({
    dateFrom: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
    dateTo: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
  });
  const [sourcesList, setSourcesList] = useState<ISource[]>([]);
  const [selectedSources, setSelectedSources] = useState<ISource[]>([]);
  const [xLabels, setXLabels] = useState<string[]>([]);
  const [wasteConcentrationData, setWasteConcentrationData] = useState<IDataset[]>([]);
  const [emissionsData, setEmissionsData] = useState<IDataset[]>([]);
  const [averages, setAverages] = useState<Array<SourceItem>>([]);

  const fetchCompanyInfo = async () => {
    getCompanyList('Water').then(async (res) => {
      const companyList: ICompany[] = res.list.map((item) => ({ ...item, name: item.name }));

      const companyPromises = companyList.map(async (item) => {
        const res = await getCompanyInfo(item.id);
        return {
          ...item,
          backgroundColor: findColorById(item.id),
          level: res.data.level,
          label: item.name,
          name: getShortName(item.name),
        };
      });

      const updatedCompanies = await Promise.all(companyPromises);

      setCompanyListWithLevel(updatedCompanies);
    });
  };

  const convertDataToTable = (data: ListItem[]) => {
    return data.map((item) => {
      return {
        key: item.companyId,
        'Точка сброса': (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 5,
            }}
          >
            <div
              style={{
                background: findColorById(item.companyId),
                width: 10,
                height: 10,
                borderRadius: '50%',
              }}
            ></div>
            {item.sourceName}
          </div>
        ),
        'Расход воды, м3/ч': item.params.WasteWaterFlowmeter.value,
        'Водородный показатель, рН': item.params.HydrogenIndex.value,
        'Температура, град С': item.params.WasteWaterTemperature.value,
        'Мутность, ЕМФ': item.params.Turbidity.value,
        'Электропроводность, мкС-микросименс': item.params.ElectricalConductivity.value,
      };
    });
  };

  const fetchTable = () => {
    setLoading(true);

    getDischarges('Water')
      .then((res) => {
        setTableState(res.list);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSourceList = async (companies: number[], type: string): Promise<ISource[]> => {
    if (companies?.length > 0) {
      const res = await getCompanySourceList(companies, type);

      const sourceList = res.list
        .map((item: ISource) => ({ ...item, label: item.name, name: item.name }))
        .sort((a: ISource, b: ISource) => a.name.localeCompare(b.name));

      setSourcesList(sourceList);
      return sourceList;
    } else {
      setSourcesList([]);
      return [];
    }
  };

  const getByField = (data: any[], value: string) => {
    return data.map((item) => item[value]);
  };

  const getLastTwentyMinutes = (sourceList: ISource[]) => {
    getTableLastTwenty(sourceList.map((item) => item.id)).then((res) => {
      setAverages(res.list);
    });
  };

  const getCharts = (sourceList: ISource[]) => {
    if (sourceList?.length > 0) {
      const colors = sourceList.map((item, index) => ({
        title: `${item.name}`,
        color: getColorLinearGraph(index),
      }));

      getLastTwentyMinutes(sourceList);

      getChartData(
        sourceList.map((source) => source.id),
        ['Wastewater'],
        selectedRangeType,
      ).then((res) => {
        const processedWasteConcentrationData: IDataset[] = [];
        const processedEmissionsData: IDataset[] = [];

        let xLabelsArrays: string[] = [];
        res.list.forEach((item) => {
          const color = colors.find((c) => c.title === item.sourceName)?.color || '';

          const emissionsChart = item.typeCharts.map((chart) =>
            chart.charts.find((c) => c.parameterKey === 'WasteWaterFlowmeter'),
          );

          const wasteConcentrationChart = item.typeCharts.map((chart) =>
            chart.charts.find((c) => c.parameterKey === 'HydrogenIndex'),
          );

          const emissionsDataArray = emissionsChart
            ? emissionsChart.map((chart) => chart && chart.param.map((p) => p.value))
            : [];

          xLabelsArrays = emissionsChart ? emissionsChart[0]?.param.map((p) => p.key) || [] : [];

          const wasteConcentrationDataArray = wasteConcentrationChart
            ? wasteConcentrationChart.map((chart) => chart && chart.param.map((p) => p.value))
            : [];

          emissionsDataArray.forEach((data, index) => {
            processedEmissionsData.push({
              data: data as number[],
              borderColor: color[index],
              backgroundColor: color[index],
              fill: true,
              tension: 0.4,
              label: `${item.typeCharts[index].type} ${item.sourceName}`,
            });
          });

          wasteConcentrationDataArray.forEach((data, index) => {
            processedWasteConcentrationData.push({
              data: data as number[],
              borderColor: color[index],
              backgroundColor: color[index],
              fill: true,
              tension: 0.4,
              label: `${item.typeCharts[index].type} ${item.sourceName}`,
            });
          });

          if (emissionsChart) {
            wasteConcentrationDataArray.forEach((data, index) => {
              if (data && emissionsChart !== undefined && emissionsChart[index] !== undefined) {
                processedEmissionsData.push({
                  data: Array(data.length).fill(emissionsChart[index]?.norma ?? 0),
                  borderColor: `${color[index]}80`,
                  backgroundColor: `${color[index]}80`,
                  fill: false,
                  tension: 0.4,
                  borderDash: [15, 15],
                  label: `Норма, ${item.typeCharts[index].type} ${item.sourceName}`,
                });
              }
            });
          }
          if (wasteConcentrationChart) {
            wasteConcentrationDataArray.forEach((data, index) => {
              if (
                data &&
                wasteConcentrationChart !== undefined &&
                wasteConcentrationChart[index] !== undefined
              ) {
                processedWasteConcentrationData.push({
                  data: Array(data.length).fill(wasteConcentrationChart[index]?.norma ?? 0),
                  borderColor: `${color[index]}80`,
                  backgroundColor: `${color[index]}80`,
                  fill: false,
                  tension: 0.4,
                  borderDash: [15, 15],
                  label: `Норма, ${item.typeCharts[index].type} ${item.sourceName}`,
                });
              }
            });
          }
        });

        setXLabels(xLabelsArrays);
        setEmissionsData(processedEmissionsData);
        setWasteConcentrationData(processedWasteConcentrationData);
      });
    } else {
      setXLabels([]);
      setEmissionsData([]);
      setWasteConcentrationData([]);
      setAverages([]);
    }
  };

  const handleSelectCompany = (companies: SetStateAction<ICompany[]>) => {
    setSelectedCompanies(companies);

    const companyIds = Array.isArray(companies) ? companies.map((company) => company.id) : [];

    getSourceList(companyIds, 'Water').then((sources) => {
      const sourcesByField = getByField(sources, 'id');

      const filteredSelectedSources = selectedSources.filter((item) =>
        sourcesByField.includes(item),
      );

      setSourcesList(sources);
      getCharts(filteredSelectedSources);
    });
  };

  const handleSelectSource = (sources: ISource[]) => {
    setSelectedSources(sources);
    getCharts(sources);
  };

  useEffect(() => {
    getCharts(selectedSources);
  }, [selectedRangeType]);

  useEffect(() => {
    fetchTable();
    fetchCompanyInfo();
  }, []);

  return (
    <div className="container">
      <CustomTable dataSource={convertDataToTable(tableState)} loading={loading} />

      <div className={classes['top-graph']}>
        <div className={classes['flex']}>
          <Select
            label="Предприятия"
            options={companyListWithLevel}
            handleSelect={handleSelectCompany}
            selectedOptions={selectedCompanies}
            isMultiple
            maxLength={5}
            contentSize={5}
          />
          <Select
            sources
            label="Источники"
            options={sourcesList}
            handleSelect={(clickedSources) => {
              handleSelectSource(clickedSources);
            }}
            selectedOptions={selectedSources}
            isMultiple
            maxLength={5}
          />
        </div>
        <SelectDates
          dates={selectedRange}
          setDates={setSelectedRange}
          setSelectedRangeType={setSelectedRangeType}
          dateRangeHide
          showCalendarIcon={false}
          optionsCustom={['День', 'Неделя', 'Месяц', 'Год']}
        />
      </div>
      <div className={classes['graphs']}>
        <Card>
          <LineChart
            datasets={emissionsData}
            chartName={'Расход воды, м3/ч'}
            measurementUnit={'м3.'}
            xLabels={xLabels}
          />
        </Card>
        <Card>
          <LineChart
            datasets={wasteConcentrationData}
            chartName={'Водородный показатель, рН'}
            measurementUnit={'pH'}
            xLabels={xLabels}
          />
        </Card>
      </div>

      <div className={classes['cards-list']}>
        {averages?.length > 0 &&
          averages.map((item) => {
            return (
              <div className={classes['cards-section']} key={item.sourceName}>
                <div className={classes['cards-title']}>
                  <span>Точка сброса:</span> <div>{item?.sourceName}</div>
                </div>
                <div className={classes['cards']}>
                  {Object.entries(item.params).map(
                    ([key, value]) =>
                      getDischargeNameByKey(key as IDischargeType)?.title && (
                        <div className={classes['card-waste']} key={key}>
                          <div className={classes['card-waste__title']}>
                            {getDischargeNameByKey(key as IDischargeType)?.title}
                          </div>
                          <div className={classes['card-waste__description']}>
                            {getDischargeNameByKey(key as IDischargeType)?.unit}
                          </div>
                          <div className={classes['card-waste__value']}>
                            {value?.toFixed(2) || '00,00'}
                          </div>
                        </div>
                      ),
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Discharges;
