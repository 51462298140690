import React, { useState } from 'react';
import classes from './styles.module.scss';
import classNames from 'classnames';
import Icon from '../icon';
import { ClosedEyeIcon, OpenEyeIcon } from 'src/assets/icons';

interface IInputProps {
  label?: string;
  placeholder?: string;
  value?: string | number | null;
  disabled?: boolean;
  type?: string;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isValid?: boolean;
  className?: string;
  errorMsg?: string;
  icon?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  variant?: 'withEdit' | 'default' | 'small';
  min?: number;
  max?: number;
}

const Input: React.FC<IInputProps> = ({
  label,
  placeholder,
  value,
  disabled,
  onChange,
  name,
  isValid,
  className,
  errorMsg,
  icon,
  type = 'text',
  onKeyDown,
  variant = 'default',
  min,
  max,
}) => {
  const [isVisiblePassword, setIsVisiblePassword] = useState<boolean>(false);

  const togglePasswordVisibility = () => setIsVisiblePassword((prev) => !prev);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
  };

  const isPassword = type === 'password';

  return (
    <div className={classNames(classes['input'], classes[`input--${variant}`], className)}>
      {label && <label className={classes['input__label']}>{label}</label>}
      <div
        className={classNames(
          classes['input__wrapper'],
          icon && classes['helper'],
          isPassword && classes['helper-password'],
        )}
      >
        {icon && <img src={icon} alt="icon" className={classes['input__front-icon']} />}
        <input
          name={name}
          type={isVisiblePassword ? 'text' : type}
          className={classNames(
            classes['input__field'],
            isValid !== undefined && !isValid && classes['input__field--invalid'],
          )}
          placeholder={placeholder}
          value={value ?? ''}
          onChange={handleInputChange}
          disabled={variant === 'default' && disabled}
          autoComplete={'one-time-code'}
          onKeyDown={onKeyDown}
          min={min}
          max={max}
          onWheel={(e: any) => e.target.blur()}
        />

        {type === 'password' && (
          <div onClick={togglePasswordVisibility}>
            {isVisiblePassword ? (
              <Icon name={'Eye'} size={20} />
            ) : (
              <Icon name={'EyeClosed'} size={20} />
            )}
          </div>
        )}

        {isValid !== undefined && !isValid && variant === 'small' && (
          <div className={classes['input__tooltip--small']} aria-label={errorMsg}>
            <Icon name={'CircleX'} size={15} />
          </div>
        )}
      </div>
      {isValid !== undefined && !isValid && variant !== 'small' && (
        <div className={classes['input__tooltip']}>{errorMsg}</div>
      )}
    </div>
  );
};

export default Input;
