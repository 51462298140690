import React, { SetStateAction, useEffect, useState } from 'react';
import { format } from 'date-fns';
import classes from './styles.module.scss';
import LineChart, { IDataset } from 'src/components/charts/line-chart';
import { ICompany, IDateRange, IDivision, IEmissionType, ISource } from 'src/consts/types';
import {
  getChartData,
  getCompanySourceList,
  getDivisionsById,
  getEmissionTypes,
} from 'src/requests/source';
import { RedArrow } from 'src/assets/icons';
import Select from 'src/components/select';
import Card from 'src/components/card';
import SelectDates from '../SelectDates';
import { PercentsImg } from 'src/assets/images';
import { getColorLinearGraph } from 'src/utils/helpers';

interface IProps {
  companyList: ICompany[];
  firstSelectedCompany?: ICompany;
  firstSelectedSource?: ISource;
  companyDivisionList?: IDivision[];
  showDivisions: boolean;
}

const Diagrams: React.FC<IProps> = ({
  companyList,
  firstSelectedCompany,
  firstSelectedSource,
  companyDivisionList,
  showDivisions,
}) => {
  const [emissionTypes, setEmissionTypes] = useState<IEmissionType[]>([]);
  const [sourcesList, setSourcesList] = useState<ISource[]>([]);
  const [listType, setListType] = useState<string>('company');

  const [selectedEmissionType, setSelectedEmissionType] = useState<string[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<(ICompany | IDivision)[]>([]);
  const [selectedSources, setSelectedSources] = useState<ISource[]>([]);

  const [emissionsData, setEmissionsData] = useState<IDataset[]>([]);
  const [wasteConcentrationData, setWasteConcentrationData] = useState<IDataset[]>([]);

  const [selectedRangeType, setSelectedRangeType] = useState<string>('TWENTY_MINUTE');
  const [selectedRange, setSelectedRange] = useState<IDateRange>({
    dateFrom: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
    dateTo: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
  });
  const hideCharts = true;

  useEffect(() => {
    setListType(showDivisions ? 'division' : 'company');
    setSelectedCompanies([]);
    setSelectedSources([]);
  }, [showDivisions]);

  const [xLabels, setXLabels] = useState<string[]>([]);

  const handleFilterClick = (type: IEmissionType) => {
    let clickedEmissionTypes = [];

    if (selectedEmissionType.includes(type.code)) {
      clickedEmissionTypes = selectedEmissionType.filter((item) => item !== type.code);
    } else {
      clickedEmissionTypes = [...selectedEmissionType, type.code];
    }
    setSelectedEmissionType(clickedEmissionTypes);
    getCharts(clickedEmissionTypes, selectedSources);
  };

  const getEmissionsList = async (): Promise<void> => {
    const sourceIds = selectedSources.map((item) => item.id);
    const res = await getEmissionTypes(sourceIds);
    setEmissionTypes(res.list);
    const firstEmissionType = res.list[0]?.code;
    setSelectedEmissionType([firstEmissionType]);
    getCharts([firstEmissionType], selectedSources);
  };

  useEffect(() => {
    firstSelectedCompany && setSelectedCompanies([firstSelectedCompany]);
    firstSelectedSource && setSelectedSources([firstSelectedSource]);
  }, [firstSelectedCompany, firstSelectedSource]);

  useEffect(() => {
    getCharts(selectedEmissionType, selectedSources);
  }, [selectedRangeType]);

  const handleChangeList = (type: string) => {
    setListType(type);
    setSelectedCompanies([]);
    setSelectedSources([]);
  };

  useEffect(() => {
    if (selectedCompanies.length === 0) {
      setSelectedSources([]);
    }
  }, [selectedCompanies]);

  useEffect(() => {
    if (firstSelectedCompany) {
      handleSelectCompany([firstSelectedCompany]);
    }
  }, [firstSelectedCompany]);

  const getCharts = (emissionTypes: string[], sourceList: ISource[]) => {
    if (sourceList?.length > 0 && selectedCompanies?.length > 0 && emissionTypes) {
      const colors = sourceList.map((item, index) => ({
        title: `${item.name}`,
        color: getColorLinearGraph(index),
      }));

      getChartData(
        sourceList.map((source) => source.id),
        emissionTypes,
        selectedRangeType,
      ).then((res) => {
        const processedEmissionsData: IDataset[] = [];
        const processedWasteConcentrationData: IDataset[] = [];
        let xLabelsArrays: string[] = [];
        res.list.forEach((item) => {
          const color = colors.find((c) => c.title === item.sourceName)?.color || '';

          const emissionsChart = item.typeCharts.map((chart) =>
            chart.charts.find((c) => c.parameterKey === 'Emissions'),
          );

          const wasteConcentrationChart = item.typeCharts.map((chart) =>
            chart.charts.find((c) => c.parameterKey === 'WasteConcentration'),
          );

          const emissionsDataArray = emissionsChart
            ? emissionsChart.map((chart) => chart && chart.param.map((p) => p.value))
            : [];

          xLabelsArrays = emissionsChart ? emissionsChart[0]?.param.map((p) => p.key) || [] : [];

          const wasteConcentrationDataArray = wasteConcentrationChart
            ? wasteConcentrationChart.map((chart) => chart && chart.param.map((p) => p.value))
            : [];

          emissionsDataArray.forEach((data, index) => {
            processedEmissionsData.push({
              data: data as number[],
              borderColor: color[index],
              backgroundColor: color[index],
              fill: true,
              tension: 0.4,
              label: `${item.typeCharts[index].type} ${item.sourceName}`,
            });
          });

          wasteConcentrationDataArray.forEach((data, index) => {
            processedWasteConcentrationData.push({
              data: data as number[],
              borderColor: color[index],
              backgroundColor: color[index],
              fill: true,
              tension: 0.4,
              label: `${item.typeCharts[index].type} ${item.sourceName}`,
            });
          });

          if (emissionsChart) {
            wasteConcentrationDataArray.forEach((data, index) => {
              if (data && emissionsChart !== undefined && emissionsChart[index] !== undefined) {
                processedEmissionsData.push({
                  data: Array(data.length).fill(emissionsChart[index]?.norma ?? 0),
                  borderColor: `${color[index]}80`,
                  backgroundColor: `${color[index]}80`,
                  fill: false,
                  tension: 0.4,
                  borderDash: [15, 15],
                  label: `Норма, ${item.typeCharts[index].type} ${item.sourceName}`,
                });
              }
            });
          }
          if (wasteConcentrationChart) {
            wasteConcentrationDataArray.forEach((data, index) => {
              if (
                data &&
                wasteConcentrationChart !== undefined &&
                wasteConcentrationChart[index] !== undefined
              ) {
                processedWasteConcentrationData.push({
                  data: Array(data.length).fill(wasteConcentrationChart[index]?.norma ?? 0),
                  borderColor: `${color[index]}80`,
                  backgroundColor: `${color[index]}80`,
                  fill: false,
                  tension: 0.4,
                  borderDash: [15, 15],
                  label: `Норма, ${item.typeCharts[index].type} ${item.sourceName}`,
                });
              }
            });
          }
        });

        setXLabels(xLabelsArrays);
        setEmissionsData(processedEmissionsData);
        setWasteConcentrationData(processedWasteConcentrationData);
      });
    } else {
      setXLabels([]);
      setEmissionsData([]);
      setWasteConcentrationData([]);
    }
  };

  useEffect(() => {
    void getEmissionsList();
  }, [selectedSources.length]);

  const getSourceList = async (companies: number[]): Promise<void> => {
    const isCompany = listType === 'company';

    if (companies?.length > 0) {
      const res = await (isCompany ? getCompanySourceList : getDivisionsById)(companies);

      const sourceList = res.list
        .map((item: ISource) => ({ ...item, label: item.name, name: item.name }))
        .sort((a: ISource, b: ISource) => a.name.localeCompare(b.name));

      setSourcesList(sourceList);
      setSelectedSources([sourceList[0]]);
    } else {
      setSourcesList([]);
    }
  };

  const handleSelectCompany = (companies: SetStateAction<(ICompany | IDivision)[]>) => {
    setSelectedCompanies(companies);

    const companyIds = Array.isArray(companies) ? companies.map((company) => company.id) : [];
    void getSourceList(companyIds);
  };

  const handleSelectSource = (sources: ISource[]) => {
    setSelectedSources(sources);
    getCharts(selectedEmissionType, sources);
  };

  return (
    <>
      <div className={classes['grid2']}>
        <div className={classes['flex']}>
          <Select<ICompany | IDivision>
            label="Предприятия"
            secondLabel="Дивизион"
            options={listType === 'company' ? companyList : companyDivisionList}
            handleSelect={handleSelectCompany}
            selectedOptions={selectedCompanies}
            isMultiple={true}
            maxLength={5}
            listType={listType}
            handleChangeList={handleChangeList}
            contentSize={5}
          />
          <Select
            sources
            label="Источники"
            options={sourcesList}
            handleSelect={(clickedSources) => {
              handleSelectSource(clickedSources);
            }}
            selectedOptions={selectedSources}
            isMultiple
            maxLength={5}
          />
        </div>
        <ul className={classes['emission-types']}>
          {emissionTypes?.map((item) => (
            <li
              key={item.code}
              onClick={() => handleFilterClick(item)}
              className={`${classes[selectedEmissionType.includes(item.code) ? 'checked' : '']} ${
                classes['emission-type']
              }`}
            >
              {item.title}
            </li>
          ))}
        </ul>
      </div>
      <SelectDates
        dates={selectedRange}
        setDates={setSelectedRange}
        showCalendarIcon={false}
        setSelectedRangeType={setSelectedRangeType}
      />
      <div className={classes['grid']}>
        <div className={classes['cards-row']}>
          <Card>
            <LineChart
              datasets={emissionsData}
              chartName={'Выбросы ЗВ, г/с'}
              measurementUnit={'г.'}
              xLabels={xLabels}
            />
          </Card>
          <Card>
            <LineChart
              datasets={wasteConcentrationData}
              chartName={'Концентрация ЗВ, мг/нм3'}
              measurementUnit={'мг.'}
              xLabels={xLabels}
            />
          </Card>
        </div>
        {!hideCharts && (
          <div className={classes['cards-row']}>
            <Card>
              <LineChart
                datasets={[
                  {
                    data: [],
                    borderColor: '',
                    backgroundColor: '',
                    fill: false,
                    tension: 0.4,
                  },
                ]}
                chartName={'Объем газовоздушной смеси, нм3/с'}
                measurementUnit={'нм3.'}
                xLabels={xLabels}
              />
            </Card>
            <div className={classes['grid__cards']}>
              <Card>
                <div className={classes['card']}>
                  <div
                    className={classes['card__number']}
                    style={{
                      display: 'flex',
                      gap: 12,
                      margin: 'auto',
                    }}
                  >
                    <div>15,</div>
                    <div>
                      <div className={classes['card__C']}>С</div>
                      <span className={classes['card__gradus']}>O</span>
                    </div>
                  </div>
                  <div className={classes['card__text-wrapper']}>
                    <div
                      className={classes['card__temp']}
                      style={{
                        gap: 0,
                      }}
                    >
                      Температура, C<span className={classes['card__gradus2']}>O</span>
                    </div>
                    <div
                      style={{
                        gap: 19,
                        alignItems: 'center',
                      }}
                    >
                      <img src={RedArrow} alt="img" />
                      <span className={classes['card__red']}>
                        на 12 <span className={classes['card__gradus3']}>O </span>С выше нормы
                      </span>
                    </div>
                  </div>
                </div>
              </Card>
              <Card>
                <div className={classes['card']}>
                  <div
                    style={{
                      margin: 'auto',
                    }}
                  >
                    <img src={PercentsImg} alt="circle" />
                  </div>
                  <div className={classes['card__text-wrapper']}>
                    <p
                      className={classes['card__text']}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        minHeight: 40,
                      }}
                    >
                      Концентрация кислорода
                    </p>
                    <div
                      style={{
                        gap: 19,
                        alignItems: 'center',
                      }}
                    >
                      <img src={RedArrow} alt="img" />
                      <span className={classes['card__red']}>на 37% выше нормы </span>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Diagrams;
