import { api } from './api';
import { IChartData, IDateRange } from '../consts/types';

const baseUrl = '/dashboard';

export type chartType = 'DAY' | 'MONTH' | 'TWENTY_MINUTE' | 'WEEK' | 'YEAR';

export const dashboardDiagram = (
  type: 'company' | 'division',
  date: { from: Date; to: Date },
  emissionType?: 'GAS' | 'DUST',
) => {
  return api
    .get(`${baseUrl}/${type}`, {
      params: {
        ...date,
        type: emissionType,
      },
    })
    .then((res) => res.data);
};

export const getEmissionType = (date: { from: Date; to: Date }, sourceId?: number[]) => {
  return api
    .get(`${baseUrl}/emissionType`, {
      params: {
        ...date,
        sourceId: sourceId ? sourceId.join(',') : undefined,
      },
    })
    .then((res) => res.data);
};

export const getDashboardChart = (
  chart: chartType,
  division: boolean,
  emissionType: string[],
  sourceIds: number[],
) => {
  return api.get<IChartData[]>(`${baseUrl}/chart`, {
    params: {
      chart,
      division,
      emissionType: emissionType.join(','),
      sourceId: sourceIds.join(','),
    },
  });
};

export const getReport = async (
  type: 'byTypeAndDate' | 'emissions' | 'concentration',
  date: IDateRange,
  sourceId: number[],
) => {
  const from = type === 'byTypeAndDate' ? date.dateFrom.split(' ')[0].slice(0, 7) : date.dateFrom.split(' ')[0];
  const to = type === 'byTypeAndDate' ? date.dateTo.split(' ')[0].slice(0, 7) : date.dateTo.split(' ')[0];

  try {
    const response = await api.get(`/report/${type}`, {
      params: {
        from,
        to,
        sourceIds: sourceId.join(','),
      },
      responseType: 'blob',
    });

    
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
    );
    const link = document.createElement('a');
    link.href = url;

    link.setAttribute(
      'download',
      `Отчет_${type}_${date.dateFrom.split(' ')[0]}_${
        date.dateTo.split(' ')[0]
      }.xlsx`,
    );
    
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Ошибка при загрузке отчета:', error);
  }
};
