import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, ChartOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { findColorById } from 'src/utils/helpers';
import DiagramTable from './components/diagram-table-2';
import Select from '../select';
import {
  DiagramCompanyType,
  DiagramDivisionType,
  DiagramEmissionType,
  IDateSelection,
  ISource,
} from 'src/consts/types';
import { externalTooltipHandler } from '../../utils/diagram';
import { Button } from '../index';
import RangePicker from '../range-picker';
import styles from './styles.module.scss';

Chart.register(ArcElement, ChartDataLabels);

const Diagram: React.FC<{
  name: string;
  list: DiagramCompanyType[] | DiagramDivisionType[] | DiagramEmissionType[];
  listType: 'company' | 'division' | 'emission';
  sourceList?: ISource[];
  selectedSources?: ISource[];
  setSelectedSources?: Dispatch<SetStateAction<ISource[]>>;
  itemNameWidth?: string;
  onDateChange: (range: IDateSelection) => void;
}> = ({
  name,
  list,
  listType,
  sourceList,
  selectedSources,
  setSelectedSources,
  itemNameWidth,
  onDateChange,
}) => {
  const navigate = useNavigate();

  const data = useMemo(() => {
    const listData = list.map((item) => item.params.reduce((acc, param) => acc + param.value, 0));
    const isEmpty = !listData?.length || listData.every((value) => value === 0);
    return isEmpty
      ? {
          labels: [''],
          datasets: [
            {
              label: '',
              data: [100],
              backgroundColor: ['#F2EEE8'],
            },
          ],
        }
      : {
          labels: list.map((item) => item.name),
          datasets: [
            {
              label: '',
              data: listData,
              backgroundColor: list.map((item) => findColorById(item.id)),
              borderColor: list.map((item) => findColorById(item.id)),
              borderWidth: 1,
            },
          ],
        };
  }, [list]);

  const options: ChartOptions<'doughnut'> = useMemo(() => {
    const listData = list.map((item) => item.params.reduce((acc, param) => acc + param.value, 0));
    const isEmpty = !listData?.length || listData.every((value) => value === 0);
    return {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          position: 'nearest',
          external: (context) => {
            const foundList = list
              ? (list as DiagramCompanyType[])?.find(
                  (item: DiagramCompanyType) =>
                    item?.name === context?.tooltip?.dataPoints?.[0]?.label,
                )
              : null;

            const filteredData = foundList?.params
              ?.map((item) => ({
                name: item?.title,
                value: item?.value,
              }))
              .filter((item) => item.name);

            filteredData && externalTooltipHandler(context, filteredData);
          },
        },
        datalabels: {
          color: '#fff',
          textStrokeColor: '#000',
          font: {
            weight: 'bold',
            size: 16,
          },
          backgroundColor: (context) => {
            const backgroundColor = context.dataset.backgroundColor as string[];
            const colorIndex = context.dataIndex;
            const color = backgroundColor[colorIndex];
            return color && !isEmpty ? `${color}CC` : 'rgba(0, 0, 0, 0.8)'; // 'CC' adds 0.8 opacity in hex
          },
          borderColor: 'white',
          borderRadius: 25,
          borderWidth: 2,
          padding: {
            top: 16,
            right: 8,
            bottom: 16,
            left: 8,
          },
          formatter: (value, context) => {
            const total = context.dataset.data.reduce(
              (sum, val) => (sum as number) + (typeof val === 'number' ? val : 0),
              0,
            );
            return isEmpty
              ? 'Нет данных'
              : value
                ? ((value / (total as number)) * 100).toFixed(1) + '%'
                : null;
          },
        },
      },
    };
  }, [list]);

  return (
    <div className={styles['card']}>
      <div className={styles['diagram__header']}>
        <div className={styles['diagram__name']}>{name}</div>
        <div className={styles['diagram__year']}>
          <RangePicker onChange={onDateChange} />
        </div>
        <div className={styles['diagram__dropdown']} style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', right: '15px' }}>
            {listType === 'emission' && (
              <Select<ISource>
                sources
                options={sourceList}
                handleSelect={setSelectedSources as Dispatch<SetStateAction<ISource[]>>}
                selectedOptions={selectedSources}
                maxLength={sourceList?.length || 5}
                listType={listType}
                placeholder="Все источники"
                isMultiple
                contentSize={1}
                right
              />
            )}
          </div>
        </div>
      </div>
      <div className={styles['diagram__row']}>
        <div className={styles['doughnut']}>
          <Doughnut data={data} options={options} />
        </div>
        <div>
          <DiagramTable list={list} itemNameWidth={itemNameWidth} />
        </div>
      </div>
      {listType !== 'emission' && (
        <div className="df jc-c">
          <Button name="Подробнее" variant={'rounded'} onClick={() => navigate(listType)} />
        </div>
      )}
    </div>
  );
};

export default Diagram;
