import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ICompany, ISource } from 'src/consts/types';
import { findTopByLatitude, findLeftByLongitude, findByRuCompanyName } from 'src/utils/helpers';
import { getCompanySourceList, getDivisionsById } from 'src/requests/source';
import KazakhstanMap from './KazakhstanMap';
import { MapContainer } from 'react-leaflet';
import classes from './styles.module.scss';

export interface IMapPoint extends ICompany {
  top: number;
  left: number;
}

interface Props {
  points: ICompany[];
  setSourcesList: Dispatch<SetStateAction<ISource[]>>;
  setSelectedCompany: Dispatch<SetStateAction<ICompany | undefined>>;
  setSelectedSource: Dispatch<SetStateAction<ISource | undefined>>;
  isDivision?: boolean;
}

const Map: React.FC<Props> = ({
  points,
  setSourcesList,
  setSelectedCompany,
  setSelectedSource,
  isDivision,
}) => {
  const [mapPoints, setMapPoints] = useState<IMapPoint[]>([]);
  const bounds: L.LatLngBoundsLiteral = [
    [39.49, 46.492],
    [55.63, 87.3152],
  ];

  const handleIconClick = (item: any, companyName?: any) => {
    const companyEnName = findByRuCompanyName(companyName);
    setSelectedCompany(item);
    (isDivision ? getDivisionsById : getCompanySourceList)([item.id]).then((res) => {
      const filteredList = res.list.filter((item: any) => item.name.includes(companyEnName));
      setSourcesList(isDivision ? filteredList : res.list);
      setSelectedSource(isDivision ? filteredList[0] : res.list[0]);
    });
  };

  useEffect(() => {
    const found = points.map((item) => ({
      ...item,
      top: findTopByLatitude(item.latitude),
      left: findLeftByLongitude(item.longitude),
    }));
    setMapPoints(found);
  }, [points]);

  return (
    <div className={classes['map']} style={{ position: 'relative' }}>
      <MapContainer
        style={{ height: '500px', width: '100%' }}
        center={[48, 68]}
        zoom={5}
        minZoom={5}
        attributionControl={false}
        maxBounds={bounds}
      >
        <KazakhstanMap
          points={mapPoints}
          isDivision={isDivision}
          handleIconClick={handleIconClick}
        />
      </MapContainer>
    </div>
  );
};

export default Map;
