import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DateRange } from 'react-date-range';
import { addDays, format } from 'date-fns';
import { ru } from 'date-fns/locale';
import classNames from 'classnames';

import { Calendar } from 'src/assets/icons';
import { getDates, getRange, getTimeZoneOffset } from 'src/utils/helpers';
import { IDateRange, IDateSelection } from 'src/consts/types';
import classes from './styles.module.scss';

interface Props {
  dates: IDateRange;
  setDates: Dispatch<SetStateAction<IDateRange>>;
  showCalendarIcon?: boolean;
  setSelectedRangeType?: Dispatch<SetStateAction<string>>;
  optionsCustom?: string[];
  dateRangeHide?: boolean;
}

const options = ['20 мин.', 'День', 'Неделя', 'Месяц', 'Год'];

const SelectDates: React.FC<Props> = ({
  dates,
  setDates,
  showCalendarIcon = true,
  setSelectedRangeType,
  optionsCustom,
  dateRangeHide,
}) => {
  const [selectedRange, setSelectedRange] = useState<string>((optionsCustom || options)[0]);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [tempDateRange, setTempDateRange] = useState<IDateSelection>({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: 'selection',
  });

  const handleChangeType = (item: string) => {
    setSelectedRange(item);
    setSelectedRangeType && setSelectedRangeType(getRange(item));
  };

  const handleRangeChange = (ranges: { selection: IDateSelection }) => {
    setTempDateRange(ranges.selection);
    const utc = {
      dateFrom: getTimeZoneOffset(ranges.selection.startDate),
      dateTo: getTimeZoneOffset(ranges.selection.endDate),
    };

    const timeWithUTC = {
      dateFrom: format(ranges.selection.startDate, 'yyyy-MM-dd HH:mm:ss.SSS') + utc.dateFrom,
      dateTo: format(ranges.selection.endDate, 'yyyy-MM-dd HH:mm:ss.SSS') + utc.dateTo,
    };

    setDates(timeWithUTC);
  };

  useEffect(() => {
    const dates = getDates(selectedRange);
    setDates({
      dateFrom: dates.startDate,
      dateTo: dates.endDate,
    });
    setTempDateRange({
      startDate: new Date(dates.startDate),
      endDate: new Date(dates.endDate),
      key: 'selection',
    });
  }, [selectedRange]);

  return (
    <div className={classes['wrapper']}>
      <ul className={classes['wrapper__period']}>
        {(optionsCustom || options).map((item: string) => (
          <li
            key={item}
            className={classNames(classes[`option`], classes[`option--${selectedRange === item}`])}
            onClick={() => handleChangeType(item)}
          >
            {item}
          </li>
        ))}
      </ul>
      {!dateRangeHide && (
        <div className={classes['wrapper__options']}>
          <div>
            <span>{new Date(dates.dateFrom).toLocaleDateString('ru-RU')}</span>{' '}
            <span>{new Date(dates.dateTo).toLocaleDateString('ru-RU')}</span>
          </div>
        </div>
      )}
      {showCalendarIcon && (
        <div className={classes['wrapper__calendar']}>
          <div
            className={classes['wrapper__calendar-box']}
            onClick={() => setShowCalendar(!showCalendar)}
          >
            <img src={Calendar} alt="calendar" />
          </div>

          {showCalendar && (
            <div className={classes['calendar-wrapper']}>
              <DateRange
                className={classes['calendar']}
                locale={ru}
                editableDateInputs={true}
                showDateDisplay={false}
                dateDisplayFormat="dd.MM.yyyy"
                staticRanges={[]}
                inputRanges={[]}
                ranges={[tempDateRange]}
                onChange={handleRangeChange}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectDates;
