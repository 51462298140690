import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useUserContext } from 'src/context/useUserContext';
import { ArrowDown } from 'src/assets/icons';
import { getAccessToken, removeTokens } from 'src/utils/token';
import emissionLogo from 'src/assets/images/emissionLogo.png';
import Popover from 'src/components/popover';
import Icon from 'src/components/icon';
import classes from './styles.module.scss';

interface IModule {
  id: number;
  title: string;
  link: string;
  disabled: boolean;
  subModules?: IModule[];
}

export const moduleList: IModule[] = [
  {
    id: 1,
    title: 'Водные ресурсы',
    link: '',
    disabled: true,
  },
  {
    id: 2,
    title: 'Атмосферный воздух',
    link: '',
    disabled: true,
  },
  {
    id: 3,
    title: 'АСМ',
    link: '',
    disabled: false,
    subModules: [
      { id: 4, title: 'Выбросы', link: '/emissions', disabled: false },
      { id: 5, title: 'Сбросы', link: '/discharges', disabled: false },
    ],
  },
  {
    id: 6,
    title: 'ОПП',
    link: '',
    disabled: true,
  },
  {
    id: 7,
    title: 'Разрешение на эмиссии',
    link: '',
    disabled: true,
  },
  {
    id: 8,
    title: 'Парниковые газы',
    link: '',
    disabled: true,
  },
  {
    id: 9,
    title: 'Управление проверками',
    link: '',
    disabled: true,
  },
  {
    id: 10,
    title: 'Управление мероприятиями в области ООС',
    link: '',
    disabled: true,
  },
  {
    id: 11,
    title: 'Управление проектами',
    link: '',
    disabled: true,
  },
];

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const { user, getUser } = useUserContext();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [showSubModules, setShowSubModules] = useState<number>(0);

  useEffect(() => {
    const token = getAccessToken();
    if (!token) {
      navigate('/login');
    }
    if (!user) {
      getUser();
    }
  }, []);

  const handleLogOut = () => {
    removeTokens();
    navigate('/login');
  };

  const handleModuleClick = (module: IModule) => {
    if (module.id === showSubModules) {
      setShowSubModules(0);
    } else if (module.subModules) {
      setShowSubModules(module.id);
    } else {
      navigate(module.link);
    }
  };

  const getUserInitials = useMemo(() => {
    const firstName = user?.firstName ? user?.firstName[0] : '';
    const lastName = user?.lastName ? user?.lastName[0] : '';
    return firstName + lastName;
  }, [user]);

  if (!user) return null;
  // TODO: shadow select
  return (
    <div className={classes['home']}>
      <div className={classes['home__background']} />
      <div className={classes['home__header']}>
        <Popover
          content={
            <>
              <div className={classes['home__profile__header']}>
                <span>{user.firstName + ' ' + user.lastName}</span>
                <span>{user.position}</span>
              </div>
              <ul className={classes['home__profile__menu']}>
                <li className={classes['home__profile__menu__item']} onClick={handleLogOut}>
                  <Icon name={'LogOut'} size={18} />
                  Выход
                </li>
              </ul>
            </>
          }
        >
          <div className={classes['home__profile']}>
            <span>{getUserInitials}</span>
          </div>
        </Popover>
      </div>
      <div className={classes['home__body']}>
        <h1>Добро пожаловать, {user?.firstName + ' ' + user?.lastName}</h1>
        <div className={classes['home__dropdown']}>
          <div
            className={classes['home__dropdown__wrap']}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <div className={classes['home__dropdown__current']}>Выберите Модуль</div>
            <img src={ArrowDown} alt="" />
          </div>
          {isDropdownOpen && (
            <div className={classes['home__dropdown__options']}>
              {moduleList.map((item: IModule) => (
                <div key={item.id}>
                  <div
                    className={classNames(
                      classes['home__dropdown__optionWrap'],
                      classes[`home__dropdown__optionWrap--${item.disabled && 'disabled'}`],
                    )}
                    onClick={() => handleModuleClick(item)}
                  >
                    <p>{item.title}</p>
                    {item.subModules && <img src={ArrowDown} alt="" />}
                  </div>
                  {showSubModules === item.id &&
                    item.subModules?.map((item) => (
                      <div
                        key={item.id}
                        className={classNames(
                          classes['home__dropdown__optionWrap--2'],
                          classes[`home__dropdown__optionWrap--${item.disabled && 'disabled'}`],
                        )}
                        onClick={() => handleModuleClick(item)}
                      >
                        <p>{item.title}</p>
                      </div>
                    ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={classes['home__logo']}>
        <img src={emissionLogo} alt="Логотип" />
      </div>
    </div>
  );
};

export default HomePage;
