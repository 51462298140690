import { api } from './api';
import { IAuthForm } from '../consts/types';
import { Roles } from 'src/consts/enum';

interface IAuthResponse {
  success: boolean;
  type: string;
  accessToken: string;
  refreshToken: string;
  msg: string;
  roles: Roles[];
}

interface ISuccessResponse {
  success: boolean;
  msg?: string;
}

const baseUrl = '/auth';

export const login = (form: IAuthForm): Promise<IAuthResponse> => {
  return api.post(`${baseUrl}/login`, form).then((res) => res.data);
};

export const getToken = (refreshToken: string): Promise<IAuthResponse> => {
  return api.post(`${baseUrl}/token`, { refreshToken }).then((res) => res.data);
};

export const forgotPassword = (email: string): Promise<ISuccessResponse> => {
  return api.post(`${baseUrl}/forgotPassword`, { email }).then((res) => res.data);
};

export const resetPassword = (
  newPassword: string,
  token: string | undefined,
): Promise<ISuccessResponse> => {
  return api
    .post(`${baseUrl}/forgotPassword/reset`, { newPassword, token })
    .then((res) => res.data);
};
