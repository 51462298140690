import { FC, useEffect, useRef, useState } from 'react';
import classes from './styles.module.scss';
import { Download } from 'lucide-react';
import Button from '../button';
import { getReport } from 'src/requests/dashboard';
import { IDateRange } from 'src/consts/types';

const DROPDOWN_ITEMS = [
  {
    type: 'byTypeAndDate',
    label: 'Выбросы ЗВ от источников',
  },
  {
    type: 'emissions',
    label: 'ПЭК',
  },
  {
    type: 'concentration',
    label: 'Аналитические данные концентраций ЗВ по источникам',
  },
];

const DownloadButton: FC<{
  sourceIds: number[];
  date: IDateRange;
}> = ({ sourceIds, date }) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const ref = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setDropdownOpen((prev) => !prev);

  const handleDownload = () => {
    if (selectedItems.length === 0 || !date.dateFrom || !date.dateTo || sourceIds.length === 0) {
      return;
    }

    selectedItems.forEach((type) => {
      getReport(type as 'byTypeAndDate' | 'emissions' | 'concentration', date, sourceIds);
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={classes['download-wrapper']} ref={ref}>
      <div onClick={toggleDropdown} className={classes['download-trigger']}>
        <div className={classes['download-icon']}>
          <Download />
        </div>
        <div className={classes['download-txt']}>Скачать отчет</div>
      </div>
      <div className={`${classes['download-dropdown']} ${dropdownOpen ? classes['open'] : ''}`}>
        <div className={classes['dropdown-header']}>СКАЧАТЬ ОТЧЕТ</div>
        {DROPDOWN_ITEMS.map(({ type, label }) => (
          <div
            key={type}
            className={`${classes['download-dropdown-item']} ${
              selectedItems.includes(type) && classes['active']
            }`}
            onClick={(e) => {
              e.stopPropagation();

              if (!selectedItems.includes(type)) {
                setSelectedItems((prev) => [...prev, type]);
              } else {
                setSelectedItems((prev) => prev.filter((item) => item !== type));
              }
            }}
          >
            <input
              type="checkbox"
              className={classes['download-checkbox']}
              checked={selectedItems.includes(type)}
              readOnly
            />
            <div>{label}</div>
          </div>
        ))}
        <div className={classes['dropdown-footer']}>
          <Button name="download-button" variant="rounded" onClick={handleDownload}>
            <div className={classes['download-button-content']}>
              <div>
                <Download />
              </div>
              <div>СКАЧАТЬ</div>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DownloadButton;
