import { Routes, Route } from 'react-router-dom';
import { LoginPage, ForgotPassword, ResetPassword, MainPage } from './pages';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './components/layout';
import HomePage from './pages/home';
import Dashboard from './pages/dashboard';
import EmissionChart from './pages/emission-charts';
import Messages from './pages/messages';
import Settings from './pages/settings';
import Discharges from './pages/discharges';

export default function RoutesIndex() {
  return (
    <>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/resetPassword/:token" element={<ResetPassword />} />
        <Route path="/" element={<HomePage />} />
        <Route element={<Layout />}>
          <Route path="/emissions" element={<MainPage />} />
          <Route path="/discharges" element={<Discharges />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/:type" element={<EmissionChart />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
}
