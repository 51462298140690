export const columns = [
  { dataIndex: 'id1', title: 'Загрязняющее вещество ' },
  // { dataIndex: 'AirExcessRatio', title: 'Коэффициент избытка воздуха, %' },
  { dataIndex: 'Emissions', title: 'Выбросы загрязняющих веществ, г/с' },
  { dataIndex: 'Overpressure', title: 'Избыточное давление (разрежение), кПа' },
  { dataIndex: 'GasFlueTemperature', title: 'Температура отходящих газов, ОС' },
  { dataIndex: 'GasFlowRate', title: 'Скорость потока отходящих газов, м/с' },
  { dataIndex: 'GasAirVolume', title: 'Объем газовоздушной смеси, нм3/с' },
  { dataIndex: 'OxygenConcentration', title: 'Концентрация кислорода, %' },
  { dataIndex: 'WasteConcentration', title: 'ПДК, мг/м3 ' },
  { dataIndex: 'Wetness', title: 'Влажность, %' },
];

export const columns3 = [
  { dataIndex: 'type', title: 'Источники ' },
  { dataIndex: 'type', title: 'Загрязняющее вещество ' },
];

export const column3ZV = [
  { dataIndex: 'upValue', title: 'г/с' },
  { dataIndex: 'downValue', title: 'тонн' },
  { dataIndex: 'duration', title: 'Длительность, мин' },
];
