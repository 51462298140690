import { FC } from 'react';
import { GeoJSON, Marker, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import kazakhstanGeoJSON from '../../../assets/geojson/kz_1.json';
import { IMapPoint } from './Map';
import { ORGicon } from 'src/assets/icons';
import L from 'leaflet';
import classes from './styles.module.scss';
import { findColorById } from 'src/utils/helpers';
import classNames from 'classnames';

const regionNamesMap: Record<string, string> = {
  Aqmola: 'Акмолинская',
  Aqtöbe: 'Актюбинская',
  Almaty: 'Алматинская',
  Atyrau: 'Атырауская',
  'East Kazakhstan': 'Восточно-Казахстанская',
  Mangghystau: 'Мангистауская',
  'North Kazakhstan': 'Северо-Казахстанская',
  Pavlodar: 'Павлодарская',
  Qaraghandy: 'Карагандинская',
  Qostanay: 'Костанайская',
  Qyzylorda: 'Кызылординская',
  'South Kazakhstan': 'Шымкент',
  'West Kazakhstan': 'Западно-Казахстанская',
  Zhambyl: 'Жамбылская',
};

const KazakhstanMap: FC<{
  points: IMapPoint[];
  isDivision?: boolean;
  handleIconClick: (item: any, companyName?: any) => void;
}> = ({ points, isDivision, handleIconClick }) => {
  const defaultStyle = {
    fillColor: '#FFFFFF',
    color: '#29349533',
    weight: 2,
    fillOpacity: 0.7,
  };

  const onEachRegion = (feature: any, layer: any) => {
    layer.on('mouseover', (e: any) => {
      const layer = e.target;
      layer.bringToFront();
    });

    const regionNameEnglish = feature.properties?.NAME_1 || 'Unknown Region';
    const regionNameRussian = regionNamesMap[regionNameEnglish] || regionNameEnglish;

    layer.bindTooltip(regionNameRussian, {
      permanent: true,
      direction: 'center',
      className: 'region-label',
    });
  };

  const customIcon = (backgroundColor: string) =>
    new L.Icon({
      iconUrl: ORGicon,
      iconSize: [32, 32],
      iconAnchor: [16, 32],
      popupAnchor: [0, -32],
      className: classes['custom-icon'],
      iconStyle: {
        backgroundColor,
      },
    });

  return (
    <>
      <GeoJSON
        data={kazakhstanGeoJSON as GeoJSON.FeatureCollection}
        style={defaultStyle}
        onEachFeature={onEachRegion}
      />
      {points.map((item) => (
        <Marker
          key={item.id}
          position={[Number(item.latitude), Number(item.longitude)]}
          icon={customIcon(item.backgroundColor || '#000')}
        >
          <Tooltip direction="center" permanent className={classes['tooltip']}>
            <span key={item.name} className={`${classes['org-point__wrapper']}`}>
              <span
                style={{ top: `${item.top - 15}%`, left: `${item.left - 7}%` }}
                className={classNames(classes[`circle`], classes[`circle--${item.level}`])}
              />
              <div
                className={classes['org-point']}
                style={{ top: `${item.top - 5}%`, left: `${item.left - 3}%` }}
                onClick={() => !isDivision && handleIconClick(item)}
              >
                {isDivision ? (
                  item.divisions.map((division) => (
                    <div
                      className={classes['org-point__division']}
                      key={division.id}
                      onClick={() => isDivision && handleIconClick(division, item.name)}
                    >
                      <img
                        src={ORGicon}
                        alt="icon"
                        style={{ backgroundColor: findColorById(division.id) }}
                      />
                      <p>{division.name}</p>
                    </div>
                  ))
                ) : (
                  <>
                    <img
                      src={ORGicon}
                      alt="icon"
                      style={{ backgroundColor: item.backgroundColor }}
                    />
                    <p>{item.name}</p>
                  </>
                )}
              </div>
            </span>
          </Tooltip>
        </Marker>
      ))}
    </>
  );
};

export default KazakhstanMap;
