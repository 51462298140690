import { TableDataType } from 'src/pages/main/Table/Table';
import { api } from './api';
import {
  ChartDataType,
  ICompany,
  IEmissionType,
  IExceedanceTableData,
  IFilter,
  IHomeInfo,
  ISource,
  ISourceInfo,
  LastTwenty,
} from 'src/consts/types';
import { ResponseData } from 'src/pages/discharges';

const baseUrl = '/source';

export const getEmissionTypes = (sourceIds: number[]): Promise<{ list: IEmissionType[] }> => {
  const route = sourceIds.length > 0 ? `/bySource` : `/all`;

  return api
    .get(`${baseUrl}/emission/types${route}`, {
      params: {
        sourceId: sourceIds.join(',') || undefined,
      },
    })
    .then((res) => res.data);
};

export const getTable1Data = (filter: IFilter): Promise<TableDataType[]> => {
  return api.post(`${baseUrl}/emission/table`, filter).then((res) => res.data);
};

export const getTable2Data = (sourceIds: number[]): Promise<any> => {
  const params = { sourceId: sourceIds.join(',') };
  return api.get(`${baseUrl}/emission/exceedance/max`, { params }).then((res) => res.data);
};

export const getTableLastTwenty = (sourceIds: number[]): Promise<any> => {
  const params = { sourceId: sourceIds.join(',') };
  return api.get<LastTwenty>(`${baseUrl}/emission/last/info`, { params }).then((res) => res.data);
};

export const getTable3Data = (sourceIds: number[]): Promise<{ list: IExceedanceTableData[] }> => {
  const params = { sourceId: sourceIds.join(',') };
  return api.get(`${baseUrl}/emission/exceedance`, { params }).then((res) => res.data);
};

export const getEmissionsDashboard = (sourceIds: number[]): Promise<{ list: ISourceInfo[] }> => {
  const params = { sourceId: sourceIds.join(',') };
  return api.get(`${baseUrl}/emission/dashboard`, { params }).then((res) => res.data);
};

export const getChartData = (
  sourceIds: number[],
  type: string[],
  chart: string,
): Promise<{ list: ChartDataType[] }> => {
  const params = { sourceId: sourceIds.join(','), type: type.join(','), chart };
  return api.get(`${baseUrl}/chart`, { params }).then((res) => res.data);
};

export const getHomePageInfo = (): Promise<{ list: IHomeInfo[] }> => {
  return api.get(`${baseUrl}/info`).then((res) => res.data);
};

export const getCompanyList = (type?: 'Water'): Promise<{ list: ICompany[] }> => {
  return api
    .get('/company/list', {
      params: {
        type,
      },
    })
    .then((res) => res.data);
};

export const getCompanySourceList = (
  companyIds: number[],
  type?: string,
): Promise<{ list: ISource[] }> => {
  const params = { id: companyIds.join(','), type };
  return api.get(`/company/source/list`, { params }).then((res) => res.data);
};

export const getCompanyInfo = (companyId: number): Promise<any> => {
  return api.get(`/company/${companyId}/info`).then((res) => res.data);
};

export const getExceedanceList = (date: string, page: number, pageSize: number) => {
  return api
    .get(`/company/exceedance/list`, { params: { date, page, pageSize } })
    .then((res) => res.data);
};

export const getDivisionsList = () => {
  return api.get(`/division/list`).then((res) => res.data);
};

export const getDivisionsById = (divisionId: number[]) => {
  const params = { divisionId: divisionId.join(',') };
  return api.get(`/division/source/list`, { params }).then((res) => res.data);
};

export const getAllSources = (): Promise<{ list: ISource[] }> => {
  return api.get(`${baseUrl}/list`).then((res) => res.data);
};

export const getDischarges = (type: 'Water') => {
  return api
    .get<ResponseData>(`${baseUrl}/info`, {
      params: { type },
    })
    .then((res) => res.data);
};
