import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import Card from 'src/components/card';
import SelectDates from '../SelectDates';
import { ICompany, IDateRange, IDivision, IEmissionType, ISource } from 'src/consts/types';
import Select from 'src/components/select';
import {
  getCompanySourceList,
  getTable1Data,
  getEmissionTypes,
  getDivisionsById,
} from 'src/requests/source';
import { columns } from '../consts';
import DownloadButton from 'src/components/download-button';

type ValueType = {
  limit: number | null;
  value: number | null;
};
export type TableDataType = {
  sourceName: string;
  params: TableDataRow;
};

export type TableDataRow = {
  [key: string]: {
    AirExcessRatio: ValueType;
    Emissions: ValueType;
    Overpressure: ValueType;
    GasFlueTemperature: ValueType;
    GasFlowRate: ValueType;
    GasAirVolume: ValueType;
    OxygenConcentration: ValueType;
    WasteConcentration: ValueType;
    WaterVaporConcenctration: ValueType;
    Wetness: ValueType;
  };
};

type PropsType = {
  companyList: ICompany[];
  dates: IDateRange;
  setDates: Dispatch<SetStateAction<IDateRange>>;
  selectedCompanies: (ICompany | IDivision)[];
  selectedSources: ISource[];
  setSelectedCompanies: Dispatch<SetStateAction<(ICompany | IDivision)[]>>;
  setSelectedSources: Dispatch<SetStateAction<ISource[]>>;
  companyDivisionList?: IDivision[];
  showDivisions?: boolean;
};

const Table: React.FC<PropsType> = ({
  showDivisions,
  companyList,
  dates,
  setDates,
  selectedCompanies,
  selectedSources,
  setSelectedCompanies,
  setSelectedSources,
  companyDivisionList,
}) => {
  const [sourcesList, setSourcesList] = useState<ISource[]>([]);
  const [emissionTypesList, setEmissionTypesList] = useState<IEmissionType[]>([]);
  const [selectedEmissionTypes, setSelectedEmissionTypes] = useState<IEmissionType[]>([]);
  const [listType, setListType] = useState<string>('company');

  const [tableData, setTableData] = useState<TableDataType[]>([]);

  useEffect(() => {
    setListType(showDivisions ? 'division' : 'company');
    setSelectedCompanies([]);
    setSelectedSources([]);
    setSelectedEmissionTypes([]);
  }, [showDivisions]);

  const handleChangeList = (type: string) => {
    setListType(type);
    setSelectedCompanies([]);
    setSelectedSources([]);
    setSelectedEmissionTypes([]);
  };

  useEffect(() => {
    if (selectedCompanies.length === 0) {
      setSelectedSources([]);
      setSelectedEmissionTypes([]);
    }
  }, [selectedCompanies]);

  useEffect(() => {
    getEmissionTypes(selectedSources.map((item) => item.id)).then((res) => {
      setEmissionTypesList(res.list);
      setSelectedEmissionTypes([res.list[0]]);
    });
  }, [selectedSources.length]);

  useEffect(() => {
    if (selectedCompanies?.length > 0) {
      if (listType === 'company') {
        getCompanySourceList(selectedCompanies.map((company) => company.id)).then((res) => {
          setSourcesList(res.list.sort((a, b) => a.name.localeCompare(b.name)));
        });
      } else {
        getDivisionsById(selectedCompanies.map((company) => company.id)).then((res) => {
          setSourcesList(res.list.sort((a: any, b: any) => a.name.localeCompare(b.name)));
        });
      }
    } else {
      setSourcesList([]);
    }
  }, [selectedCompanies]);

  useEffect(() => {
    selectedSources.length > 0 &&
      selectedEmissionTypes.length > 0 &&
      getTable1Data({
        dateFrom: dates.dateFrom,
        dateTo: dates.dateTo,
        sourceIds: selectedSources.map((item) => Number(item.id)),
        types: selectedEmissionTypes.map((item) => item.code),
      }).then((res) => setTableData(res));
  }, [selectedSources, selectedEmissionTypes, dates.dateFrom, dates.dateTo]);

  return (
    <Card>
      <div className={classes['wrapper']}>
        <div className={classes['wrapper-top']}>
          <div className={classes['wrapper__header']}>
            <div>
              <Select<ICompany | IDivision>
                label="Предприятия"
                secondLabel="Дивизион"
                options={listType === 'company' ? companyList : companyDivisionList}
                handleSelect={setSelectedCompanies}
                selectedOptions={selectedCompanies}
                isMultiple={true}
                maxLength={5}
                listType={listType}
                handleChangeList={handleChangeList}
              />
              <Select
                sources
                label="Источники"
                options={sourcesList}
                handleSelect={setSelectedSources}
                selectedOptions={selectedSources}
                isMultiple={true}
                maxLength={5}
              />
              <Select
                label="Загрязняющее вещество"
                options={emissionTypesList}
                handleSelect={setSelectedEmissionTypes}
                selectedOptions={selectedEmissionTypes}
                isMultiple={true}
                valueKey={'code'}
                labelKey={'title'}
                maxLength={emissionTypesList.length}
              />
            </div>
          </div>
          <div className={classes['wrapper__dates']}>
            <SelectDates dates={dates} setDates={setDates} />
          </div>
        </div>
        <div className='df jc-fe'>
        <DownloadButton sourceIds={selectedSources.map((item) => item.id)} date={dates} /></div>
        <div className={classes['table__name']}>Общие данные</div>
        <div style={{ width: '100%', overflowX: 'auto' }}>
          <table className={classes['table']}>
            <colgroup>
              {columns.map((item, index) => (
                <col key={item.dataIndex} className={classes[index % 2 === 0 ? 'gradient' : '']} />
              ))}
            </colgroup>
            <thead>
              <tr>
                <th rowSpan={2}>Источники </th>
                <th rowSpan={2}>Загрязняющее вещество </th>
                {/* <th rowSpan={2}>Коэффициент избытка воздуха, %</th> */}
                <th colSpan={2}>Выбросы загрязняющих веществ, г/с</th>
                <th rowSpan={2}>Избыточное давление (разрежение), кПа</th>
                <th rowSpan={2}>Температура отходящих газов, ОС</th>
                <th rowSpan={2}>Скорость потока отходящих газов, м/с</th>
                <th rowSpan={2}>Объем газовоздушной смеси, нм3/с</th>
                <th rowSpan={2}>Концентрация кислорода, %</th>
                <th colSpan={2}>ПДК, мг/м3</th>
                <th rowSpan={2}>Влажность, %</th>
              </tr>
              <tr>
                <th>Норматив </th>
                <th>Факт</th>
                <th>Норматив</th>
                <th>Факт</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData
                  .sort((a, b) => a.sourceName.localeCompare(b.sourceName))
                  .map((item, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td
                          className={classes['sourceName']}
                          rowSpan={Object.keys(item.params).length + 1}
                        >
                          {item.sourceName}
                        </td>
                      </tr>
                      {Object.entries(item.params)?.map(([key, value], keyIndex) => (
                        <tr key={`${index}-${keyIndex}`}>
                          <td className={classes['sourceKey']}>{key}</td>
                          <td>{value.AirExcessRatio?.value}</td>
                          <td>{value.Emissions?.limit}</td>
                          <td>{value.Emissions?.value}</td>
                          <td>{value.Overpressure?.value}</td>
                          <td>{value.GasFlueTemperature?.value}</td>
                          <td>{value.GasFlowRate?.value}</td>
                          <td>{value.GasAirVolume?.value}</td>
                          <td>{value.OxygenConcentration?.value}</td>
                          <td>{value.WasteConcentration?.limit}</td>
                          <td>{value.WasteConcentration?.value}</td>
                          <td>{value.Wetness?.value}</td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    </Card>
  );
};

export default Table;
